import {
    GET_DEFAULT_ADDRESS,
    GET_ADDRESS,
    UPDATE_DEFAULT,
    STORE_ADDRESS,
    GET_SINGLE_ADDRESS,
    DELETE_ADDRESS,
    GET_ACCOUNTS,
    VERIFY_ACCOUNT,
    UPDATE_ADDRESS,
    USER_MSISDN
} from "../actions/types";

const initialState = {
    defaultAddress: {
        isLoading: false,
        hasError: false,
        message: "",
        data: {}
    },
    getAccounts: {
        isLoading: false,
        hasError: false,
        message: "",
        data: {}
    },
    verifyAccounts: {
        isLoading: false,
        hasError: false,
        message: "",
        data: {}
    },

    allAddresses: {
        isLoading: false,
        hasError: false,
        message: "",
        data: {}
    },

    updateDefault: {
        isLoading: false,
        hasError: false,
        message: "",
        data: {}
    },

    storeAddress: {
        isLoading: false,
        hasError: false,
        message: "",
        data: {}
    },
    userMsisdn: {
        isLoading: false,
        hasError: false,
        message: "",
        data: {}
    },

    singleAddress: {
        isLoading: false,
        hasError: false,
        message: "",
        data: {}
    },

    addressDelete: {
        isLoading: false,
        hasError: false,
        message: "",
        data: {}
    },

    updateAddress: {
        isLoading: false,
        hasError: false,
        message: "",
        data: {}
    }
};

export default function(state = initialState, action) {
    switch (action.type) {
        case GET_DEFAULT_ADDRESS:
            return {
                ...state,
                defaultAddress: action.payload
            };
        case GET_ADDRESS:
            return {
                ...state,
                allAddresses: action.payload
            };
        case GET_ACCOUNTS:
            return {
                ...state,
                getAccounts: action.payload
            };
        case VERIFY_ACCOUNT:
            return {
                ...state,
                verifyAccounts: action.payload
            };
        case UPDATE_DEFAULT:
            return {
                ...state,
                updateDefault: action.payload
            };

        case STORE_ADDRESS:
            return {
                ...state,
                storeAddress: action.payload
            };
        case USER_MSISDN:
            return {
                ...state,
                userMsisdn: action.payload
            };

        case GET_SINGLE_ADDRESS:
            return {
                ...state,
                singleAddress: action.payload
            };

        case DELETE_ADDRESS:
            return {
                ...state,
                addressDelete: action.payload
            };

        case UPDATE_ADDRESS:
            return {
                ...state,
                updateAddress: action.payload
            };

        default:
            return state;
    }
}
