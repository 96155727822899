import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import authReducer from "./authReducer";
import cartReducer from "./cartReducer";
import branchReducer from "./branchReducer";
import notificationReducer from "./notificationReducer";
import productsReducer from "./productsReducer";
import orderReducer from "./orderReducer";
import promoCodeReducer from "./promoCodeReducer";
import userAddressReducer from "./userAddressReducer";
import category from "./productsReducer";

const rootReducer = combineReducers({
    auth: authReducer,
    cart: cartReducer,
    notification: notificationReducer,
    products: productsReducer,
    branch: branchReducer,
    order: orderReducer,
    promoCode: promoCodeReducer,
    userAddress: userAddressReducer,
    category: productsReducer
});

const persistConfig = {
    key: "root",
    storage: storage,
    // whitelist: ["auth"] // which reducer want to store
    blacklist: ["order.orderDetail"]
};

export default persistReducer(persistConfig, rootReducer);
