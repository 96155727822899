import {
    GET_ORDERS,
    GET_ORDER_DETAIL,
    CANCEL_ORDER,
    CHECK_FEEDBACK_STATUS,
    SUBMIT_ORDER_FEEDBACK,
    PLACE_ORDER
} from "../actions/types";

const initialState = {
    orders: {
        isLoading: true,
        hasError: false,
        message: "",
        data: {}
    },
    placeOrder: {
        isLoading: true,
        hasError: false,
        message: "",
        data: {}
    },
    orderDetail: {
        isLoading: true,
        hasError: false,
        message: "",
        data: {}
    },
    cancelOrder: {
        isLoading: true,
        hasError: false,
        message: "",
        data: {}
    },
    orderFeedbackShow: {
        isLoading: true,
        hasError: false,
        message: "",
        status: false,
        orderId: "",
        type: ""
    },
    orderFeedbackSubmit: {
        isLoading: false,
        hasError: false,
        message: "",
        data: {}
    }
};

export default function(state = initialState, action) {
    switch (action.type) {
        case GET_ORDERS:
            return {
                ...state,
                orders: action.payload
            };
        case PLACE_ORDER:
            return {
                ...state,
                placeOrder: action.payload
            };
        case GET_ORDER_DETAIL:
            return {
                ...state,
                orderDetail: action.payload
            };
        case CANCEL_ORDER:
            return {
                ...state,
                cancelOrder: action.payload
            };
        case CHECK_FEEDBACK_STATUS:
            return {
                ...state,
                orderFeedbackShow: action.payload
            };

        case SUBMIT_ORDER_FEEDBACK:
            return {
                ...state,
                orderFeedbackSubmit: action.payload
            };

        default:
            return state;
    }
}
