import { GET_PROMO, GET_VOUCHERS, INVALID_PROMO } from "../actions/types";

const initialState = {
    promoCode: {
        isLoading: true,
        hasError: false,
        data: []
    },
    invalidPromo: {
        isLoading: true,
        hasError: false,
        data: []
    },
    vouchers: {
        isLoading: true,
        hasError: false,
        data: []
    }
};
export default function(state = initialState, action) {
    switch (action.type) {
        case GET_PROMO:
            return {
                ...state,
                promoCode: action.payload
            };
        case INVALID_PROMO:
            return {
                ...state,
                invalidPromo: action.payload
            };
        case GET_VOUCHERS:
            return {
                ...state,
                vouchers: action.payload
            };
        default:
            return state;
    }
}
