import ReactGA from "react-ga4";
// import Hotjar from '@hotjar/browser';

const TRACKING_ID = "G-MRLDVRN1YY"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

// const siteId = 3632071;
// const hotjarVersion = 6;
// Hotjar.init(siteId, hotjarVersion);


/**
 * First we will load all of this project's JavaScript dependencies which
 * includes React and other helpers. It's a great starting point while
 * building robust, powerful web applications using React + Laravel.
 */
import {useAuthState} from 'react-firebase-hooks/auth';
import {auth} from './firebase';

require("./bootstrap");

/**
 * Next, we will create a fresh React component instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

require("./Index");

