import firebase from 'firebase';

const firebaseConfig = {
    apiKey: "AIzaSyCqBpk76ZFA8Euas9YuYZcyim_KufyxBOI",
    authDomain: "farmtohome-test.firebaseapp.com",
    databaseURL: "https://farmtohome-test.firebaseio.com",
    projectId: "farmtohome-test",
    storageBucket: "farmtohome-test.appspot.com",
    messagingSenderId: "817117311156",
    appId: "1:817117311156:web:ebcb5ca9d422fd82bf67e1",
    measurementId: "G-7F191VRK01"
};

firebase.initializeApp(firebaseConfig);
var auth = firebase.auth();
export {auth , firebase};
