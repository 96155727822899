import { APP_ERROR, SEND_CONTACTUS_EMAIL } from "../actions/types";

const initialState = {
    appError: {
        isLoading: true,
        hasError: false
    },
    contactUsEmail: {
        isLoading: false,
        hasError: false,
        message: "",
        data: {}
    }
};

export default function(state = initialState, action) {
    switch (action.type) {
        case APP_ERROR:
            return {
                ...state,
                appError: {
                    isLoading: false,
                    hasError: action.payload
                }
            };
        case SEND_CONTACTUS_EMAIL:
            return {
                ...state,
                contactUsEmail: action.payload
            };
        default:
            return state;
    }
}
