import {
    SIGN_IN,
    SOCIAL_SIGN_IN,
    SIGN_UP,
    USER_INFO,
    USER_PROFILE_UPDATE,
    USER_PASSWORD_UPDATE,
    SIGN_OUT,
    REQUEST_FORGET_PASSWORD,
    FORGET_PASSWORD_VALIDATE,
    FORGET_PASSWORD_CHANGE
} from "../actions/types";

const initialState = {
    user: {
        isLoading: true,
        hasError: false,
        isLoggedIn: false,
        data: [],
        accessToken: ""
    },
    signup: {
        isLoading: false,
        hasError: false,
        data: [],
        message: ""
    },
    socialSignin: {
        isLoading: false,
        hasError: false,
        isRegister: false,
        data: [],
        message: ""
    },
    signin: {
        isLoading: false,
        hasError: false,
        data: [],
        message: ""
    },
    userProfileUpdate: {
        isLoading: false,
        hasError: false,
        data: [],
        message: ""
    },
    userPasswordUpdate: {
        isLoading: false,
        hasError: false,
        data: [],
        message: ""
    },
    forgotPassword: {
        isLoading: false,
        hasError: false,
        data: {},
        message: ""
    },
    forgotPasswordValidate: {
        isLoading: false,
        hasError: false,
        data: {},
        message: ""
    },
    forgotPasswordChange: {
        isLoading: false,
        hasError: false,
        data: {},
        message: ""
    }
};

export default function(state = initialState, action) {
    switch (action.type) {
        case SIGN_IN:
            return {
                ...state,
                signin: action.payload
            };
        case SIGN_UP:
            return {
                ...state,
                signup: action.payload
            };
        case SOCIAL_SIGN_IN:
            return {
                ...state,
                socialSignin: action.payload
            };
        case USER_INFO:
            return {
                ...state,
                user: action.payload
            };
        case USER_PROFILE_UPDATE:
            return {
                ...state,
                userProfileUpdate: action.payload
            };
        case USER_PASSWORD_UPDATE:
            return {
                ...state,
                userPasswordUpdate: action.payload
            };
        case SIGN_OUT:
            return {
                ...state,
                user: action.payload
            };
        case REQUEST_FORGET_PASSWORD:
            return {
                ...state,
                forgotPassword: action.payload
            };
        case FORGET_PASSWORD_VALIDATE:
            return {
                ...state,
                forgotPasswordValidate: action.payload
            };
        case FORGET_PASSWORD_CHANGE:
            return {
                ...state,
                forgotPasswordChange: action.payload
            };
        default:
            return state;
    }
}
