//auth actions
export const SIGN_IN = "SIGN_IN";
export const SIGN_UP = "SIGN_UP";
export const USER_INFO = "USER_INFO";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const USER_PROFILE_UPDATE = "USER_PROFILE_UPDATE";
export const USER_PASSWORD_UPDATE = "USER_PASSWORD_UPDATE";
export const SIGN_OUT = "SIGN_OUT";
export const SOCIAL_SIGN_IN = "SOCIAL_SIGN_IN";
export const SOCIAL_SIGN_UP = "SOCIAL_SIGN_UP";

// branch actions
export const GET_BRANCH_FAQS = "GET_BRANCH_FAQS";
export const GET_BRANCH_OPTIONS = "GET_BRANCH_OPTIONS";
export const GET_BRANCH = "GET_BRANCH";
export const BRANCH_MODAL = "BRANCH_MODAL";
export const SELECTED_BRANCH = "SELECTED_BRANCH";

export const GET_PRODUCT = "GET_PRODUCT";
export const GET_PRODUCTS = "GET_PRODUCTS";
export const GET_PRODUCTS_NAMES = "GET_PRODUCTS_NAMES";
export const GET_CATEGORY_PRODUCTS = "GET_CATEGORY_PRODUCTS";
export const GET_FEATURED_PRODUCTS = "GET_FEATURED_PRODUCTS";
export const SUBMIT_PRODUCT_REVIEW = "SUBMIT_PRODUCT_REVIEW";
export const GET_SEARCH_PRODUCTS = "GET_SEARCH_PRODUCTS";

export const ADD_TO_CART = "ADD_TO_CART";
export const UPDATE_CART = "UPDATE_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const DELETE_CART = "DELETE_CART";
export const UPDATE_LOCAL_CART = "UPDATE_LOCAL_CART";
export const CART_ACTION = "CART_ACTION";
export const CART_MODAL = "CART_MODAL";
export const LOGIN_MODAL = "LOGIN_MODAL";
export const CART_NOTIFY = "CART_NOTIFY";

export const GET_ORDERS = "GET_ORDERS";
export const PLACE_ORDER = "PLACE_ORDER";
export const GET_ORDER_DETAIL = "GET_ORDER_DETAIL";
export const CANCEL_ORDER = "CANCEL_ORDER";
export const CHECK_FEEDBACK_STATUS = "CHECK_FEEDBACK_STATUS";
export const SUBMIT_ORDER_FEEDBACK = "SUBMIT_ORDER_FEEDBACK";

export const REQUEST_FORGET_PASSWORD = "REQUEST_FORGET_PASSWORD";
export const FORGET_PASSWORD_VALIDATE = "FORGET_PASSWORD_VALIDATE";
export const FORGET_PASSWORD_CHANGE = "FORGET_PASSWORD_CHANGE";

export const SEND_CONTACTUS_EMAIL = "SEND_CONTACTUS_EMAIL";

export const APP_ERROR = "APP_ERROR";
export const OUT_OF_STOCK = "OUT_OF_STOCK";
export const GET_USER_CART = "GET_USER_CART";

//PROMO CODE
export const GET_PROMO = "GET_PROMO";
export const INVALID_PROMO = "INVALID_PROMO";
export const GET_VOUCHERS = "GET_VOUCHERS";

export const GET_CHARGES = "GET_CHARGES";

// USER ADDRESS
export const GET_DEFAULT_ADDRESS = "GET_DEFAULT_ADDRESS";
export const GET_ADDRESS = "GET_ADDRESS";
export const UPDATE_DEFAULT = "UPDATE_DEFAULT";
export const STORE_ADDRESS = "STORE_ADDRESS";
export const GET_SINGLE_ADDRESS = "GET_SINGLE_ADDRESS";
export const DELETE_ADDRESS = "DELETE_ADDRESS";
export const UPDATE_ADDRESS = "UPDATE_ADDRESS";

//User Accounts
GET_ACCOUNTS;
export const GET_ACCOUNTS = "GET_ACCOUNTS";
export const VERIFY_ACCOUNT = "VERIFY_ACCOUNT";

// category
export const GET_CATEGORY = "GET_CATEGORY";
export const USER_MSISDN  = "USER_MSISDN";
export const USER_BRANCH  = "USER_BRANCH";
