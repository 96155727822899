import {
    ADD_TO_CART,
    REMOVE_FROM_CART,
    UPDATE_CART,
    DELETE_CART,
    CART_ACTION,
    CART_MODAL,
    CART_NOTIFY,
    GET_USER_CART,
    LOGIN_MODAL,
    GET_CHARGES
} from "../actions/types";

const initialState = {
    cart: {
        isLoading: false,
        hasError: false,
        message: "",
        branchCart: []
    },
    cartAction: {
        isLoading: false,
        hasError: false,
        message: ""
    },
    cartModal: {
        status: false,
        product: {}
    },
    loginModal: {
        status: false,
        product: {}
    },
    showCartNotifyModal: {
        isLoading: false,
        status: false
    },
    userCart: {
        isLoading: false,
        status: false,
        data:""
    },
    getCharges: {
        isLoading: false,
        status: false,
        data:""
    }
};

export default function(state = initialState, action) {
    switch (action.type) {
        case GET_USER_CART:
            return {
                ...state,
                userCart: action.payload
            };
        case ADD_TO_CART:
            return {
                ...state,
                cart: action.payload
            };
        case UPDATE_CART:
            return {
                ...state,
                cart: action.payload
            };
        case REMOVE_FROM_CART:
            return {
                ...state,
                cart: action.payload
            };
        case DELETE_CART:
            return {
                ...state,
                cart: action.payload
            };
        case CART_ACTION:
            return {
                ...state,
                cartAction: action.payload
            };
        case CART_MODAL:
            return {
                ...state,
                cartModal: action.payload
            };
        case LOGIN_MODAL:
            return {
                ...state,
                loginModal: action.payload
            };
        case CART_NOTIFY:
            return {
                ...state,
                showCartNotifyModal: action.payload
            };
        case GET_CHARGES:
            return {
                ...state,
                getCharges: action.payload
            };
        default:
            return state;
    }
}
