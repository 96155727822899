import {
    SIGN_IN,
    GET_CATEGORY_PRODUCTS,
    GET_FEATURED_PRODUCTS,
    GET_PRODUCTS,
    GET_PRODUCT,
    GET_PRODUCTS_NAMES,
    SIGN_OUT,
    SUBMIT_PRODUCT_REVIEW,
    GET_SEARCH_PRODUCTS,
    GET_CATEGORY
} from "./types";
import * as Sentry from "@sentry/react";

export const getCategoryProducts = () => (dispatch, getState) => {
    try {
        dispatch({
            type: GET_CATEGORY_PRODUCTS,
            payload: {
                isLoading: true,
                hasError: false,
                data: {},
                message: ""
            }
        });
        const branch = getState().branch.branchSelected;
        const auth = getState().auth.user;
        const branchId = branch.isBranchSelected
            ? branch.branch.deliveryLocations.filter(
                  obj => obj.area === branch.branchArea
              )
            : 1;
        const userType = auth.isLoggedIn ? auth.data.type : 1;
        const cityId = branchId.map(obj => obj.cityId);
        const sectorId = branchId.map(obj => obj.id);

        axios
            .get(`/api/v2/categories?city_id=${cityId}&sector_id=${sectorId}`)
            .then(res => {
                dispatch({
                    type: GET_CATEGORY_PRODUCTS,
                    payload: {
                        isLoading: false,
                        hasError: false,
                        data: res.data.data,
                        message: res.data.message
                    }
                });
            })
            .catch(err => {
                if (err.response.data.statusCode == 400) {
                    dispatch({
                        type: GET_CATEGORY_PRODUCTS,
                        payload: {
                            isLoading: false,
                            hasError: true,
                            message: err.response.data.message
                        }
                    });
                } else {
                    Sentry.withScope(scope => {
                        scope.setExtra(
                            "title",
                            "productsActions@getCategoryProducts"
                        );
                        scope.setExtra("response", err.response);
                        Sentry.captureException(err);
                    });
                    dispatch({
                        type: GET_CATEGORY_PRODUCTS,
                        payload: {
                            isLoading: false,
                            hasError: true,
                            message:
                                "Something went wrong please try again later"
                        }
                    });
                }
            });
    } catch (err) {
        Sentry.withScope(scope => {
            scope.setExtra("title", "productsActions@getCategoryProducts");
            scope.setExtra("response", err);
            Sentry.captureException(err);
        });
        dispatch({
            type: GET_CATEGORY_PRODUCTS,
            payload: {
                isLoading: false,
                hasError: true,
                message: "Something went wrong please try again later"
            }
        });
    }
};

export const getFeaturedProducts = () => (dispatch, getState) => {
    try {
        dispatch({
            type: GET_FEATURED_PRODUCTS,
            payload: {
                isLoading: true,
                hasError: false,
                data: {},
                message: ""
            }
        });
        const branch = getState().branch.branchSelected;
        const auth = getState().auth.user;
        const branchId = branch.isBranchSelected ? branch.branch.id : 1;
        const userType = auth.isLoggedIn ? auth.data.type : 1;
        axios
            .get(
                `/api/featured/products?city_id=${branchId}&user_type=${userType}`
            )
            .then(res => {
                dispatch({
                    type: GET_FEATURED_PRODUCTS,
                    payload: {
                        isLoading: false,
                        hasError: false,
                        data: res.data.data,
                        message: res.data.message
                    }
                });
            })
            .catch(err => {
                if (err.response.data.statusCode == 400) {
                    dispatch({
                        type: GET_FEATURED_PRODUCTS,
                        payload: {
                            isLoading: false,
                            hasError: true,
                            message: err.response.data.message
                        }
                    });
                } else {
                    Sentry.withScope(scope => {
                        scope.setExtra(
                            "title",
                            "productsActions@getCategoryProducts"
                        );
                        scope.setExtra("response", err.response);
                        Sentry.captureException(err);
                    });
                    dispatch({
                        type: GET_FEATURED_PRODUCTS,
                        payload: {
                            isLoading: false,
                            hasError: true,
                            message:
                                "Something went wrong please try again later"
                        }
                    });
                }
            });
    } catch (err) {
        Sentry.withScope(scope => {
            scope.setExtra("title", "productsActions@getCategoryProducts");
            scope.setExtra("response", err);
            Sentry.captureException(err);
        });
        dispatch({
            type: GET_FEATURED_PRODUCTS,
            payload: {
                isLoading: false,
                hasError: true,
                message: "Something went wrong please try again later"
            }
        });
    }
};

export const getProducts = category => (dispatch, getState) => {
    try {
        dispatch({
            type: GET_PRODUCTS,
            payload: {
                isLoading: true,
                hasError: false,
                data: {},
                message: ""
            }
        });
        const branch = getState().branch.branchSelected;
        const auth = getState().auth.user;
        const branchId = branch.isBranchSelected ? branch.branchId : 1;
        const userType = auth.isLoggedIn ? auth.data.type : 1;


        const selectedBranch = branch.isBranchSelected
        ? branch.branch.deliveryLocations.filter(
              obj => obj.area === branch.branchArea
          )
        : 1;
        const sectorId  = selectedBranch.map(obj => obj.id);
        axios
            .get(
                `/api/v3/products?city_id=${branchId}&sector_id=${sectorId[0]}&user_type=${userType}&category=${category}`
            )
            .then(res => {
                dispatch({
                    type: GET_PRODUCTS,
                    payload: {
                        isLoading: false,
                        hasError: false,
                        data: res.data.data,
                        message: res.data.message
                    }
                });
            })
            .catch(err => {
                if (err.response.data.statusCode == 401) {
                    dispatch({
                        type: SIGN_OUT,
                        payload: {
                            isLoading: false,
                            hasError: true,
                            isLoggedIn: false,
                            message: "Session timeout please sign in."
                        }
                    });
                    dispatch({
                        type: SIGN_IN,
                        payload: {
                            isLoading: false,
                            hasError: true,
                            message: "Session timeout please sign in."
                        }
                    });
                } else if (err.response.data.statusCode == 400) {
                    dispatch({
                        type: GET_PRODUCTS,
                        payload: {
                            isLoading: false,
                            hasError: true,
                            message: err.response.data.message
                        }
                    });
                } else {
                    Sentry.withScope(scope => {
                        scope.setExtra(
                            "title",
                            "masterProductsActions@getProducts"
                        );
                        scope.setExtra("response", err.response);
                        Sentry.captureException(err);
                    });
                    dispatch({
                        type: GET_PRODUCTS,
                        payload: {
                            isLoading: false,
                            hasError: true,
                            message:
                                "Something went wrong please try again later"
                        }
                    });
                }
            });
    } catch (err) {
        Sentry.withScope(scope => {
            scope.setExtra("title", "masterProductsActions@getProducts");
            scope.setExtra("response", err);
            Sentry.captureException(err);
        });
        dispatch({
            type: GET_PRODUCTS,
            payload: {
                isLoading: false,
                hasError: true,
                message: "Something went wrong please try again later"
            }
        });
    }
};

export const getProductsNames = () => dispatch => {
    try {
        dispatch({
            type: GET_PRODUCTS_NAMES,
            payload: {
                isLoading: true,
                hasError: false,
                data: {},
                message: ""
            }
        });
        axios
            .get("/api/names/products")
            .then(res => {
                dispatch({
                    type: GET_PRODUCTS_NAMES,
                    payload: {
                        isLoading: false,
                        hasError: false,
                        data: res.data.data,
                        message: res.data.message
                    }
                });
            })
            .catch(err => {
                if (err.response.data.statusCode == 401) {
                    dispatch({
                        type: SIGN_OUT,
                        payload: {
                            isLoading: false,
                            hasError: true,
                            isLoggedIn: false,
                            message: "Session timeout please sign in."
                        }
                    });
                    dispatch({
                        type: SIGN_IN,
                        payload: {
                            isLoading: false,
                            hasError: true,
                            message: "Session timeout please sign in."
                        }
                    });
                } else if (err.response.data.statusCode == 400) {
                    dispatch({
                        type: GET_PRODUCTS_NAMES,
                        payload: {
                            isLoading: false,
                            hasError: true,
                            message: err.response.data.message
                        }
                    });
                } else {
                    Sentry.withScope(scope => {
                        scope.setExtra(
                            "title",
                            "masterProductsActions@getProductsNames"
                        );
                        scope.setExtra("response", err.response);
                        Sentry.captureException(err);
                    });
                    dispatch({
                        type: GET_PRODUCTS_NAMES,
                        payload: {
                            isLoading: false,
                            hasError: true,
                            message:
                                "Something went wrong please try again later"
                        }
                    });
                }
            });
    } catch (err) {
        Sentry.withScope(scope => {
            scope.setExtra("title", "masterProductsActions@getProductsNames");
            scope.setExtra("response", err);
            Sentry.captureException(err);
        });
        dispatch({
            type: GET_PRODUCTS_NAMES,
            payload: {
                isLoading: false,
                hasError: true,
                message: "Something went wrong please try again later"
            }
        });
    }
};

export const getCategory = nameEn => (dispatch, getState) => {
    try {
        dispatch({
            type: GET_CATEGORY,
            payload: {
                isLoading: true,
                hasError: false,
                data: {},
                message: ""
            }
        });
        const branch = getState().branch.branchSelected;
        const auth = getState().auth.user;
        const branchId = branch.isBranchSelected ? branch.branch.id : 1;
        const userType = auth.isLoggedIn ? auth.data.type : 1;
        axios
            .get(
                `/api/category?name_en=${nameEn}&city_id=${branchId}&user_type=${userType}`
            )
            .then(res => {
                dispatch({
                    type: GET_CATEGORY,
                    payload: {
                        isLoading: false,
                        hasError: false,
                        data: res.data.data,
                        isEmpty: res.data.statusCode == 400 ? true : false,
                        message: res.data.message
                    }
                });
            })
            .catch(err => {
                if (err.response.data.statusCode == 401) {
                    dispatch({
                        type: SIGN_OUT,
                        payload: {
                            isLoading: false,
                            hasError: true,
                            isLoggedIn: false,
                            message: "Session timeout please sign in."
                        }
                    });
                    dispatch({
                        type: SIGN_IN,
                        payload: {
                            isLoading: false,
                            hasError: true,
                            message: "Session timeout please sign in."
                        }
                    });
                } else if (err.response.data.statusCode == 400) {
                    dispatch({
                        type: GET_CATEGORY,
                        payload: {
                            isLoading: false,
                            hasError: true,
                            message: err.response.data.message
                        }
                    });
                } else {
                    Sentry.withScope(scope => {
                        scope.setExtra(
                            "title",
                            "masterProductsActions@getProducts"
                        );
                        scope.setExtra("response", err.response);
                        Sentry.captureException(err);
                    });
                    dispatch({
                        type: GET_CATEGORY,
                        payload: {
                            isLoading: false,
                            hasError: true,
                            message:
                                "Something went wrong please try again later"
                        }
                    });
                }
            });
    } catch (err) {
        Sentry.withScope(scope => {
            scope.setExtra("title", "masterProductsActions@getProducts");
            scope.setExtra("response", err);
            Sentry.captureException(err);
        });
        dispatch({
            type: GET_CATEGORY,
            payload: {
                isLoading: false,
                hasError: true,
                message: "Something went wrong please try again later"
            }
        });
    }
};

export const getProduct = productId => (dispatch, getState) => {
    try {
        dispatch({
            type: GET_PRODUCT,
            payload: {
                isLoading: true,
                hasError: false,
                data: {},
                message: ""
            }
        });
        const branch = getState().branch.branchSelected;
        const auth = getState().auth.user;
        const branchId = branch.isBranchSelected ? branch.branchId : 1;
        const userType = auth.isLoggedIn ? auth.data.type : 1;


        const selectedBranch = branch.isBranchSelected
        ? branch.branch.deliveryLocations.filter(
              obj => obj.area === branch.branchArea
          )
        : 1;
        const sectorId = selectedBranch.map(obj => obj.id);
        axios
            .get(
                `/api/v2/products/${productId}?city_id=${branchId}&sectorId=${sectorId[0]}&user_type=${userType}`
            )
            .then(res => {
                dispatch({
                    type: GET_PRODUCT,
                    payload: {
                        isLoading: false,
                        hasError: false,
                        data: res.data.data,
                        isEmpty: res.data.statusCode == 400 ? true : false,
                        message: res.data.message
                    }
                });
            })
            .catch(err => {
                if (err.response.data.statusCode == 401) {
                    dispatch({
                        type: SIGN_OUT,
                        payload: {
                            isLoading: false,
                            hasError: true,
                            isLoggedIn: false,
                            message: "Session timeout please sign in."
                        }
                    });
                    dispatch({
                        type: SIGN_IN,
                        payload: {
                            isLoading: false,
                            hasError: true,
                            message: "Session timeout please sign in."
                        }
                    });
                } else if (err.response.data.statusCode == 400) {
                    dispatch({
                        type: GET_PRODUCT,
                        payload: {
                            isLoading: false,
                            hasError: true,
                            message: err.response.data.message
                        }
                    });
                } else {
                    Sentry.withScope(scope => {
                        scope.setExtra(
                            "title",
                            "masterProductsActions@getProducts"
                        );
                        scope.setExtra("response", err.response);
                        Sentry.captureException(err);
                    });
                    dispatch({
                        type: GET_PRODUCT,
                        payload: {
                            isLoading: false,
                            hasError: true,
                            message:
                                "Something went wrong please try again later"
                        }
                    });
                }
            });
    } catch (err) {
        Sentry.withScope(scope => {
            scope.setExtra("title", "masterProductsActions@getProducts");
            scope.setExtra("response", err);
            Sentry.captureException(err);
        });
        dispatch({
            type: GET_PRODUCT,
            payload: {
                isLoading: false,
                hasError: true,
                message: "Something went wrong please try again later"
            }
        });
    }
};

export const getSearchProducts = string => (dispatch, getState) => {
    try {
        dispatch({
            type: GET_SEARCH_PRODUCTS,
            payload: {
                isLoading: true,
                hasError: false,
                data: {},
                message: ""
            }
        });
        const branch = getState().branch.branchSelected;
        const auth = getState().auth.user;
        const branchId = branch.isBranchSelected ? branch.branchId : 1;
        const userType = auth.isLoggedIn ? auth.data.type : 1;

        const selectedBranch = branch.isBranchSelected
        ? branch.branch.deliveryLocations.filter(
              obj => obj.area === branch.branchArea
          )
        : 1;
        const sectorId  = selectedBranch.map(obj => obj.id);

        axios
            .get(
                `/api/search/products?city_id=${branchId}&user_type=${userType}&search=${string}&sector_id=${sectorId}`
            )
            .then(res => {
                dispatch({
                    type: GET_SEARCH_PRODUCTS,
                    payload: {
                        isLoading: false,
                        hasError: false,
                        data: res.data.data,
                        message: res.data.message
                    }
                });
            })
            .catch(err => {
                if (err.response.data.statusCode == 400) {
                    dispatch({
                        type: GET_SEARCH_PRODUCTS,
                        payload: {
                            isLoading: false,
                            hasError: true,
                            message: err.response.data.message
                        }
                    });
                } else {
                    Sentry.withScope(scope => {
                        scope.setExtra(
                            "title",
                            "masterProductsActions@getSearchProducts"
                        );
                        scope.setExtra("response", err.response);
                        Sentry.captureException(err);
                    });
                    dispatch({
                        type: GET_SEARCH_PRODUCTS,
                        payload: {
                            isLoading: false,
                            hasError: true,
                            message:
                                "Something went wrong please try again later"
                        }
                    });
                }
            });
    } catch (err) {
        Sentry.withScope(scope => {
            scope.setExtra("title", "masterProductsActions@getSearchProducts");
            scope.setExtra("response", err);
            Sentry.captureException(err);
        });
        dispatch({
            type: GET_SEARCH_PRODUCTS,
            payload: {
                isLoading: false,
                hasError: true,
                message: "Something went wrong please try again later"
            }
        });
    }
};

export const submitProductReview = data => (dispatch, getState) => {
    try {
        dispatch({
            type: SUBMIT_PRODUCT_REVIEW,
            payload: {
                isLoading: true,
                hasError: false,
                data: {},
                message: ""
            }
        });
        const auth = getState().auth.user;
        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${auth.accessToken}`;
        axios
            .post("/api/user/review", data)
            .then(res => {
                dispatch({
                    type: SUBMIT_PRODUCT_REVIEW,
                    payload: {
                        isLoading: false,
                        hasError: false,
                        data: res.data.data,
                        message: res.data.message
                    }
                });
            })
            .catch(err => {
                if (err.response.data.statusCode == 401) {
                    dispatch({
                        type: SIGN_OUT,
                        payload: {
                            isLoading: false,
                            hasError: true,
                            isLoggedIn: false,
                            message: "Session timeout please sign in."
                        }
                    });
                    dispatch({
                        type: SIGN_IN,
                        payload: {
                            isLoading: false,
                            hasError: true,
                            message: "Session timeout please sign in."
                        }
                    });
                } else if (err.response.data.statusCode == 400) {
                    dispatch({
                        type: SUBMIT_PRODUCT_REVIEW,
                        payload: {
                            isLoading: false,
                            hasError: true,
                            message: err.response.data.message
                        }
                    });
                } else {
                    Sentry.withScope(scope => {
                        scope.setExtra(
                            "title",
                            "masterProductsActions@submitProductReview"
                        );
                        scope.setExtra("response", err.response);
                        Sentry.captureException(err);
                    });
                    dispatch({
                        type: SUBMIT_PRODUCT_REVIEW,
                        payload: {
                            isLoading: false,
                            hasError: true,
                            message:
                                "Something went wrong please try again later"
                        }
                    });
                }
            });
    } catch (err) {
        Sentry.withScope(scope => {
            scope.setExtra(
                "title",
                "masterProductsActions@submitProductReview"
            );
            scope.setExtra("response", err);
            Sentry.captureException(err);
        });
        dispatch({
            type: SUBMIT_PRODUCT_REVIEW,
            payload: {
                isLoading: false,
                hasError: true,
                message: "Something went wrong please try again later"
            }
        });
    }
};
