import {
    GET_BRANCH_OPTIONS,
    GET_BRANCH,
    SELECTED_BRANCH,
    BRANCH_MODAL,
    GET_BRANCH_FAQS,
    USER_BRANCH
} from "../actions/types";

const initialState = {
    branchOptions: {
        isLoading: true,
        hasError: false,
        message: "",
        data: []
    },
    branch: {
        isLoading: true,
        hasError: false,
        data: [],
        message: ""
    },
    showBranchModal: {
        isLoading: false,
        hasError: false,
        isActive: false,
        message: ""
    },
    branchSelected: {
        isBranchSelected: false,
        branch: {},
        branchId: "",
        branchArea: "",
        zoneId:"",
        isLoading: false,
        hasError: false,
        message: "",
        defaultCityId:"",
        defaultZoneId:""

    },
    userBranch: {
        defaultCityId:"",
        defaultZoneId:""
    },
    faqs: {
        isLoading: false,
        hasError: false,
        data: {},
        message: ""
    }
};

export default function(state = initialState, action) {
    switch (action.type) {
        case GET_BRANCH_OPTIONS:
            return {
                ...state,
                branchOptions: action.payload
            };
        case GET_BRANCH:
            return {
                ...state,
                branch: action.payload
            };
        case BRANCH_MODAL:
            return {
                ...state,
                showBranchModal: action.payload
            };
        case SELECTED_BRANCH:
            return {
                ...state,
                branchSelected: action.payload
            };
        case USER_BRANCH:
            return {
                ...state,
                userBranch: action.payload
            };
        case GET_BRANCH_FAQS:
            return {
                ...state,
                faqs: action.payload
            };
        default:
            return state;
    }
}
