import {
    SIGN_IN,
    SOCIAL_SIGN_IN,
    SIGN_UP,
    USER_INFO,
    USER_PROFILE_UPDATE,
    USER_PASSWORD_UPDATE,
    REQUEST_FORGET_PASSWORD,
    FORGET_PASSWORD_VALIDATE,
    REFRESH_TOKEN,
    SIGN_OUT,
    FORGET_PASSWORD_CHANGE
} from "./types";
import cookie from "js-cookie";
import { isIOS, isSafari } from "react-device-detect";
import * as Sentry from "@sentry/react";
import useAnalyticsEventTracker from '../../js/components/Ga';
import { getCart } from "./branchActions";

export const signIn = (msisdn, password) => {
    return async dispatch => {
        const data = { msisdn: msisdn, password: password, platform: 3 };
        dispatch({
            type: SIGN_IN,
            payload: {
                isLoading: true,
                hasError: false,
                data: {},
                message: ""
            }
        });
        await axios
            .post("/api/auth/signin", data)
            .then(res => {
                cookie.set("fth_token", res.data.accessToken);
                if (isIOS && !isSafari) {
                    window.webkit.messageHandlers.JSListener.postMessage(
                        res.data.accessToken
                    );
                }
                dispatch({
                    type: USER_INFO,
                    payload: {
                        isLoading: false,
                        hasError: false,
                        isLoggedIn: true,
                        message: res.data.message,
                        data: res.data.data,
                        accessToken: res.data.accessToken
                    }
                });
                dispatch({
                    type: SIGN_IN,
                    payload: {
                        isLoading: false,
                        hasError: false,
                        data: [],
                        message: ""
                    }
                });
            })
            .catch(err => {
                if (
                    err.response.data.statusCode == 401 ||
                    err.response.data.statusCode
                ) {
                    dispatch({
                        type: SIGN_IN,
                        payload: {
                            isLoading: false,
                            hasError: true,
                            data: err.response.data,
                            message: err.response.data.message
                        }
                    });
                } else {
                    Sentry.withScope(scope => {
                        scope.setExtra("title", "authAction@signIn");
                        scope.setExtra("response", err.response);
                        Sentry.captureException(err);
                    });
                    dispatch({
                        type: SIGN_IN,
                        payload: {
                            isLoading: false,
                            hasError: true,
                            data: {},
                            message: "Something went wrong, try again later"
                        }
                    });
                }
            });
    };
};

export const getUserInfo = () => {
    return async dispatch => {
        try {
            const token = cookie.get("fth_token");
            axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
            await axios
                .post("/api/auth/user")
                .then(res => {
                    dispatch({
                        type: USER_INFO,
                        payload: {
                            isLoading: false,
                            isLoggedIn: true,
                            data: res.data.data,
                            message: res.data.message,
                            accessToken: token
                        }
                    });
                })
                .catch(err => {
                    if (err.response.data.statusCode == 400) {
                        dispatch({
                            type: SIGN_OUT,
                            payload: {
                                isLoading: false,
                                hasError: true,
                                isLoggedIn: false,
                                data: {},
                                message: err.response.data.message,
                                accessToken: null
                            }
                        });
                    } else if (err.response.data.statusCode == 401) {
                        dispatch({
                            type: SIGN_OUT,
                            payload: {
                                isLoading: false,
                                hasError: true,
                                isLoggedIn: false,
                                data: {},
                                message: err.response.data.message,
                                accessToken: null
                            }
                        });
                        dispatch({
                            type: SIGN_IN,
                            payload: {
                                isLoading: false,
                                hasError: true,
                                data: {},
                                message: "Session timeout, please login"
                            }
                        });
                    } else {
                        Sentry.withScope(scope => {
                            scope.setExtra("title", "authAction@getUserInfo");
                            scope.setExtra("response", err.response);
                            Sentry.captureException(err);
                        });
                        dispatch({
                            type: SIGN_OUT,
                            payload: {
                                isLoading: false,
                                hasError: true,
                                isLoggedIn: false,
                                message:
                                    "Something went wrong please try again later",
                                data: {},
                                accessToken: null
                            }
                        });
                    }
                });
        } catch (err) {
            Sentry.withScope(scope => {
                scope.setExtra("title", "authAction@getUserInfo");
                scope.setExtra("response", err);
                Sentry.captureException(err);
            });
            dispatch({
                type: SIGN_OUT,
                payload: {
                    isLoading: false,
                    hasError: true,
                    isLoggedIn: false,
                    message: "Something went wrong please try again later",
                    data: {},
                    accessToken: null
                }
            });
        }
    };
};

export const refreshToken = token => {
    return async dispatch => {
        try {
            axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
            await axios
                .post("/api/auth/refresh")
                .then(res => {
                    cookie.set("fth_token", res.data.accessToken);
                    dispatch({
                        type: REFRESH_TOKEN,
                        payload: {
                            isLoading: false,
                            hasError: false,
                            isLoggedIn: true,
                            data: res.data.data,
                            message: res.data.message,
                            accessToken: res.data.accessToken
                        }
                    });
                })
                .catch(err => {
                    if (err.response.status == 400) {
                        dispatch({
                            type: SIGN_OUT,
                            payload: {
                                isLoading: false,
                                hasError: false,
                                isLoggedIn: false,
                                data: {},
                                message: err.response.data.message,
                                accessToken: null
                            }
                        });
                    } else if (err.response.data.statusCode == 401) {
                        dispatch({
                            type: SIGN_OUT,
                            payload: {
                                isLoading: false,
                                hasError: true,
                                isLoggedIn: false,
                                data: {},
                                message: err.response.data.message,
                                accessToken: null
                            }
                        });
                        dispatch({
                            type: SIGN_IN,
                            payload: {
                                isLoading: false,
                                hasError: true,
                                data: {},
                                message: "Session timeout, please login"
                            }
                        });
                    } else {
                        Sentry.withScope(scope => {
                            scope.setExtra("title", "authAction@refreshToken");
                            scope.setExtra("response", err.response);
                            Sentry.captureException(err);
                        });
                        dispatch({
                            type: SIGN_OUT,
                            payload: {
                                isLoading: false,
                                hasError: true,
                                isLoggedIn: false,
                                message:
                                    "Something went wrong, please try again later",
                                data: {},
                                accessToken: null
                            }
                        });
                    }
                });
        } catch (err) {
            Sentry.withScope(scope => {
                scope.setExtra("title", "authAction@refreshToken");
                scope.setExtra("response", err);
                Sentry.captureException(err);
            });
            dispatch({
                type: SIGN_OUT,
                payload: {
                    isLoading: false,
                    hasError: true,
                    isLoggedIn: false,
                    message: "Something went wrong please try again later",
                    data: {},
                    accessToken: null
                }
            });
        }
    };
};

export const signOut = token => {
    return async dispatch => {
        try {
            axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
            await axios
                .post("/api/auth/logout")
                .then(res => {
                    cookie.remove("fth_token");
                    dispatch({
                        type: SIGN_OUT,
                        payload: {
                            isLoading: false,
                            hasError: false,
                            isLoggedIn: false,
                            data: {},
                            message: "",
                            accessToken: null
                        }
                    });
                })
                .catch(err => {
                    if (
                        err.response.status == 400 &&
                        err.response.status == 401
                    ) {
                        dispatch({
                            type: SIGN_OUT,
                            payload: {
                                isLoading: false,
                                hasError: false,
                                isLoggedIn: false,
                                data: {},
                                message: "",
                                accessToken: null
                            }
                        });
                    } else {
                        Sentry.withScope(scope => {
                            scope.setExtra("title", "authAction@signOut");
                            scope.setExtra("response", err.response);
                            Sentry.captureException(err);
                        });
                        dispatch({
                            type: SIGN_OUT,
                            payload: {
                                isLoading: false,
                                hasError: true,
                                isLoggedIn: false,
                                data: {},
                                message:
                                    "Something went wrong, please try again later",
                                accessToken: null
                            }
                        });
                    }
                });
        } catch (err) {
            Sentry.withScope(scope => {
                scope.setExtra("title", "authAction@signOut");
                scope.setExtra("response", err);
                Sentry.captureException(err);
            });
            dispatch({
                type: SIGN_OUT,
                payload: {
                    isLoading: false,
                    hasError: true,
                    isLoggedIn: false,
                    data: {},
                    message: "Something went wrong, please try again later",
                    accessToken: null
                }
            });
        }
    };
};

export const putUserProfileUpdate = (data, token) => {
    return async dispatch => {
        try {
            axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
            await axios
                .post("/api/user/update", data)
                .then(res => {
                    dispatch({
                        type: USER_PROFILE_UPDATE,
                        payload: {
                            isLoading: false,
                            hasError: false,
                            data: res.data,
                            message: res.data.message
                        }
                    });
                })
                .catch(err => {
                    if (err.response.data.statusCode == 400) {
                        dispatch({
                            type: USER_PROFILE_UPDATE,
                            payload: {
                                isLoading: false,
                                hasError: true,
                                data: err.response.data,
                                message: err.response.data.message
                            }
                        });
                    } else if (err.response.data.statusCode == 401) {
                        dispatch({
                            type: SIGN_OUT,
                            payload: {
                                isLoading: false,
                                hasError: true,
                                isLoggedIn: false,
                                data: {},
                                message: err.response.data.message,
                                accessToken: null
                            }
                        });
                        dispatch({
                            type: SIGN_IN,
                            payload: {
                                isLoading: false,
                                hasError: true,
                                data: {},
                                message: "Session timeout, please login"
                            }
                        });
                    } else {
                        Sentry.withScope(scope => {
                            scope.setExtra("title", "authAction@updateUser");
                            scope.setExtra("response", err.response);
                            Sentry.captureException(err);
                        });
                        dispatch({
                            type: USER_PROFILE_UPDATE,
                            payload: {
                                isLoading: false,
                                hasError: true,
                                message:
                                    "Something went wrong please try again later",
                                data: err.response.data
                            }
                        });
                    }
                });
        } catch (err) {
            Sentry.withScope(scope => {
                scope.setExtra("title", "authAction@updateUser");
                scope.setExtra("response", err);
                Sentry.captureException(err);
            });
            dispatch({
                type: USER_PROFILE_UPDATE,
                payload: {
                    isLoading: false,
                    hasError: true,
                    message: "Something went wrong please try again later",
                    data: {}
                }
            });
        }
    };
};

export const putUserPasswordUpdate = (data, token) => {
    return async dispatch => {
        try {
            axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
            await axios
                .post("/api/user/changepassword", data)
                .then(res => {
                    dispatch({
                        type: USER_PASSWORD_UPDATE,
                        payload: {
                            isLoading: false,
                            hasError: false,
                            data: res.data,
                            message: res.data.message
                        }
                    });
                })
                .catch(err => {
                    if (err.response.data.statusCode == 400) {
                        dispatch({
                            type: USER_PASSWORD_UPDATE,
                            payload: {
                                isLoading: false,
                                hasError: true,
                                data: err.response.data,
                                message: err.response.data.message
                            }
                        });
                    } else if (err.response.data.statusCode == 401) {
                        dispatch({
                            type: SIGN_OUT,
                            payload: {
                                isLoading: false,
                                hasError: true,
                                isLoggedIn: false,
                                data: {},
                                message: err.response.data.message,
                                accessToken: null
                            }
                        });
                        dispatch({
                            type: SIGN_IN,
                            payload: {
                                isLoading: false,
                                hasError: true,
                                data: {},
                                message: "Session timeout, please login"
                            }
                        });
                    } else {
                        Sentry.withScope(scope => {
                            scope.setExtra("title", "authAction@updateUser");
                            scope.setExtra("response", err.response);
                            Sentry.captureException(err);
                        });
                        dispatch({
                            type: USER_PASSWORD_UPDATE,
                            payload: {
                                isLoading: false,
                                hasError: true,
                                message:
                                    "Something went wrong please try again later",
                                data: err.response.data
                            }
                        });
                    }
                });
        } catch (err) {
            Sentry.withScope(scope => {
                scope.setExtra("title", "authAction@updateUser");
                scope.setExtra("response", err);
                Sentry.captureException(err);
            });
            dispatch({
                type: USER_PASSWORD_UPDATE,
                payload: {
                    isLoading: false,
                    hasError: true,
                    message: "Something went wrong please try again later",
                    data: {}
                }
            });
        }
    };
};

export const signUp = data => {
    return async dispatch => {
        dispatch({
            type: SIGN_UP,
            payload: {
                isLoading: true,
                hasError: false,
                data: {},
                message: ""
            }
        });
        await axios
            .post("/api/auth/signup", data)
            .then(res => {
                cookie.set("fth_token", res.data.accessToken);
                if(data.type == 1){
                    const customerSignUp = useAnalyticsEventTracker('Customer Sign Up - FTH');
                    customerSignUp('Customer Sign Up - FTH');
                }
                if(data.type == 2){
                    const businessSignUp = useAnalyticsEventTracker('Business Sign Up - FTH');
                    businessSignUp('Business Sign Up - FTH');
                }

                if (isIOS) {
                    window.webkit.messageHandlers.JSListener.postMessage(
                        res.data.accessToken
                    );
                }
                dispatch({
                    type: USER_INFO,
                    payload: {
                        isLoading: false,
                        hasError: false,
                        isLoggedIn: true,
                        message: res.data.message,
                        data: res.data.data,
                        accessToken: res.data.accessToken
                    }
                });
                dispatch({
                    type: SIGN_UP,
                    payload: {
                        isLoading: false,
                        hasError: false,
                        data: [],
                        message: res.data.message
                    }
                });
            })
            .catch(err => {
                if (err.response.data.statusCode == 400) {
                    dispatch({
                        type: SIGN_UP,
                        payload: {
                            isLoading: false,
                            hasError: true,
                            data: err.response.data,
                            message: err.response.data.message
                        }
                    });
                } else {
                    Sentry.withScope(scope => {
                        scope.setExtra("title", "authAction@socialSignUp");
                        scope.setExtra("response", err.response);
                        Sentry.captureException(err);
                    });
                    dispatch({
                        type: SIGN_UP,
                        payload: {
                            isLoading: false,
                            hasError: true,
                            data: {},
                            message: "Something went wrong, try again later"
                        }
                    });
                }
            });
    };
};

export const socialSignUp = data => {
    return async dispatch => {
        dispatch({
            type: SIGN_UP,
            payload: {
                isLoading: true,
                hasError: false,
                data: {},
                message: ""
            }
        });
        await axios
            .post("/api/auth/signup/social", data)
            .then(res => {
                sessionStorage.removeItem("social_data");
                sessionStorage.removeItem("page");
                cookie.set("fth_token", res.data.accessToken);
                if (isIOS && !isSafari) {
                    window.webkit.messageHandlers.JSListener.postMessage(
                        res.data.accessToken
                    );
                }
                dispatch({
                    type: SIGN_UP,
                    payload: {
                        isLoading: false,
                        hasError: false,
                        data: [],
                        message: res.data.message
                    }
                });
            })
            .catch(err => {
                if (err.response.data.statusCode == 400) {
                    dispatch({
                        type: SIGN_UP,
                        payload: {
                            isLoading: false,
                            hasError: true,
                            data: err.response.data,
                            message: err.response.data.message
                        }
                    });
                } else {
                    Sentry.withScope(scope => {
                        scope.setExtra("title", "authAction@socialSignUp");
                        scope.setExtra("response", err.response);
                        Sentry.captureException(err);
                    });
                    dispatch({
                        type: SIGN_UP,
                        payload: {
                            isLoading: false,
                            hasError: true,
                            data: {},
                            message: "Something went wrong, try again later"
                        }
                    });
                }
            });
    };
};

export const socialSignIn = data => {
    return async dispatch => {
        dispatch({
            type: SOCIAL_SIGN_IN,
            payload: {
                isLoading: true,
                hasError: false,
                isRegister: false,
                data: {},
                message: ""
            }
        });
        await axios
            .post("/api/auth/signin/social", data)
            .then(res => {
                cookie.set("fth_token", res.data.accessToken);
                if (isIOS && !isSafari) {
                    window.webkit.messageHandlers.JSListener.postMessage(
                        res.data.accessToken
                    );
                }
                dispatch({
                    type: SOCIAL_SIGN_IN,
                    payload: {
                        isLoading: false,
                        hasError: false,
                        isRegister: false,
                        data: res.data,
                        message: res.data.message
                    }
                });
            })
            .catch(err => {
                if (
                    err.response.status == 400 &&
                    err.response.data.statusCode == 200
                ) {
                    dispatch({
                        type: SOCIAL_SIGN_IN,
                        payload: {
                            isLoading: false,
                            hasError: false,
                            isRegister: true,
                            data: err.response.data,
                            message: err.response.data.message
                        }
                    });
                } else {
                    Sentry.withScope(scope => {
                        scope.setExtra("title", "authAction@socialSignIn");
                        scope.setExtra("response", err.response);
                        Sentry.captureException(err);
                    });
                    dispatch({
                        type: SOCIAL_SIGN_IN,
                        payload: {
                            isLoading: false,
                            hasError: true,
                            isRegister: false,
                            data: {},
                            message: "Something went wrong, try again later"
                        }
                    });
                }
            });
    };
};

export const requestForgetPassword = data => {
    return async dispatch => {
        dispatch({
            type: REQUEST_FORGET_PASSWORD,
            payload: {
                isLoading: true,
                hasError: false,
                data: {},
                message: ""
            }
        });

        await axios
            // .post("/api/auth/password/reset", data) //previous api to change password
            .post("/api/auth/checkuser", {'msisdn': data})
            .then(res => {
                dispatch({
                    type: REQUEST_FORGET_PASSWORD,
                    payload: {
                        isLoading: false,
                        hasError: false,
                        data: res.data,
                        message: res.data.message,
                        token:res.data.token
                    }
                });
            })
            .catch(err => {
                if (err.response.status == 404) {
                    dispatch({
                        type: REQUEST_FORGET_PASSWORD,
                        payload: {
                            isLoading: false,
                            hasError: true,
                            data: err.response.data,
                            message: err.response.data.message
                        }
                    });
                } else {
                    Sentry.withScope(scope => {
                        scope.setExtra(
                            "title",
                            "authAction@requestForgetPassword"
                        );
                        scope.setExtra("response", err.response);
                        Sentry.captureException(err);
                    });
                    dispatch({
                        type: REQUEST_FORGET_PASSWORD,
                        payload: {
                            isLoading: false,
                            hasError: true,
                            data: {},
                            message: "Something went wrong, try again later"
                        }
                    });
                }
            });
    };
};

export const forgetPasswordValidate = data => {
    return async dispatch => {
        dispatch({
            type: FORGET_PASSWORD_VALIDATE,
            payload: {
                isLoading: true,
                hasError: false,
                data: {},
                message: ""
            }
        });
        await axios
            .post("/api/auth/password/reset/validate", data)
            .then(res => {
                dispatch({
                    type: FORGET_PASSWORD_VALIDATE,
                    payload: {
                        isLoading: false,
                        hasError: false,
                        data: res.data,
                        message: res.data.message
                    }
                });
            })
            .catch(err => {
                if (err.response.status == 400) {
                    dispatch({
                        type: FORGET_PASSWORD_VALIDATE,
                        payload: {
                            isLoading: false,
                            hasError: true,
                            data: err.response.data,
                            message: err.response.data.message
                        }
                    });
                } else {
                    Sentry.withScope(scope => {
                        scope.setExtra(
                            "title",
                            "authAction@requestForgetPassword"
                        );
                        scope.setExtra("response", err.response);
                        Sentry.captureException(err);
                    });
                    dispatch({
                        type: FORGET_PASSWORD_VALIDATE,
                        payload: {
                            isLoading: false,
                            hasError: true,
                            data: {},
                            message: "Something went wrong, try again later"
                        }
                    });
                }
            });
    };
};

export const forgetPasswordChange = data => {
    return async dispatch => {
        dispatch({
            type: FORGET_PASSWORD_CHANGE,
            payload: {
                isLoading: true,
                hasError: false,
                data: {},
                message: ""
            }
        });
        await axios
            .post("/api/auth/password/reset/change", data)
            .then(res => {
                cookie.set("fth_token", res.data.accessToken);
                if (isIOS && !isSafari) {
                    window.webkit.messageHandlers.JSListener.postMessage(
                        res.data.accessToken
                    );
                }
                dispatch({
                    type: FORGET_PASSWORD_CHANGE,
                    payload: {
                        isLoading: false,
                        hasError: false,
                        data: res.data,
                        message: res.data.message
                    }
                });
            })
            .catch(err => {
                if (err.response.status == 400) {
                    dispatch({
                        type: FORGET_PASSWORD_CHANGE,
                        payload: {
                            isLoading: false,
                            hasError: true,
                            data: err.response.data,
                            message: err.response.data.message
                        }
                    });
                } else {
                    Sentry.withScope(scope => {
                        scope.setExtra(
                            "title",
                            "authAction@requestForgetPassword"
                        );
                        scope.setExtra("response", err.response);
                        Sentry.captureException(err);
                    });
                    dispatch({
                        type: FORGET_PASSWORD_CHANGE,
                        payload: {
                            isLoading: false,
                            hasError: true,
                            data: {},
                            message: "Something went wrong, try again later"
                        }
                    });
                }
            });
    };
};
