import React, { Component, Suspense, lazy } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import cookie from "js-cookie";
import { getUserInfo } from "../actions/authActions";
import { getBranchOptions, getCart } from "../actions/branchActions";
import { getCategoryProducts } from "../actions/productsActions";
import { addToCart, clearCart } from "../actions/cartActions";
import {
    updateSelectedBranch,
    userDefaultBranch
} from "../actions/branchActions";

// import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import "toastr/build/toastr.min.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const Welcome = lazy(() => import("./Welcome"));
const Product = lazy(() => import("./Product"));
const Aboutus = lazy(() => import("./Aboutus"));
const Contactus = lazy(() => import("./Contactus"));
const PrivacyPolicy = lazy(() => import("./PrivacyPolicy"));
const AccountDeletionPolicy = lazy(() => import("./AccountDeletionPolicy"));
const Faqs = lazy(() => import("./Faqs"));
const DeliveryCoverage = lazy(() => import("./DeliveryCoverage"));
const Cart = lazy(() => import("./Cart"));
const Shop = lazy(() => import("./Shop"));
const Search = lazy(() => import("./Search"));
const GuestCheckout = lazy(() => import("./GuestCheckout"));
// const AppLogin = lazy(() => import("./AppLogin"));

const GuestRoute = lazy(() => import("./auth/GuestRoute"));
const PublicRoute = lazy(() => import("./auth/PublicRoute"));
const AuthRoute = lazy(() => import("./auth/AuthRoute"));
const SocialRoute = lazy(() => import("./auth/SocialRoute"));
const ForgetPassRoute = lazy(() => import("./auth/ForgetPassRoute"));
const Signin = lazy(() => import("./auth/Signin"));
const Signup = lazy(() => import("./auth/Signup"));
const ForgetPassword = lazy(() => import("./auth/ForgetPassword"));
const SocialProfile = lazy(() => import("./auth/SocialProfile"));
const PasswordPincode = lazy(() => import("./auth/PasswordPincode"));
const ChangePassword = lazy(() => import("./auth/ChangePassword"));

const Checkout = lazy(() => import("./user/Checkout"));
const OrderHistory = lazy(() => import("./user/OrderHistory"));
const UserProfile = lazy(() => import("./user/Profile"));
const OrderDetail = lazy(() => import("./user/OrderDetail"));
const UserChangePassword = lazy(() => import("./user/ChangePassword"));
const AppLogin = lazy(() => import("./AppLogin"));
// const UserReviews = lazy(() => import("./user/Reviews"));

const Default = lazy(() => import("./Default"));

class App extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (cookie.get("fth_token")) {
            this.props.getUserInfo();
        }
        this.props.getBranchOptions();

        // Sentry.init({
        //     dsn:
        //         "https://f613e19bb503437584a6c858496808da@o498380.ingest.sentry.io/5575839",
        //     autoSessionTracking: true,
        //     integrations: [new Integrations.BrowserTracing()],
        //     environment: process.env.NODE_ENV,
        //     release: "1.0.0",
        //     tracesSampleRate: 1.0
        // });
    }
    componentDidUpdate(prevProps) {
        if (this.props.auth.accessToken) {
            if (Object.entries(this.props.userCart.data).length != 0) {
                this.props.userCart.data.forEach(item => {
                    const data = {
                        product: {
                            ...item.product,
                            category: item.product.category.nameEn
                        },
                        quantity: item.quantity,
                        remarks: item.remarks,
                        branchId: item.city_id,
                        isBundle: item.isBundle,
                        bundleItemQty: item.bundleItemQty,
                    };
                    this.props.addToCart(data, this.props.auth.accessToken);
                });
            }
        }

        if (
            prevProps.branchSelected.zoneId !==
            this.props.branchSelected.zoneId
        )
        {
            this.props.getCart(this.props.auth.accessToken);
            this.props.getCategoryProducts();
        }
        if (
            prevProps.branchSelected.branchId !==
            this.props.branchSelected.branchId
        )
        {
            // console.log('getBranchOptions');
            this.props.getBranchOptions();

        }
        // if (prevProps.auth.accessToken !== this.props.auth.accessToken) {
        //     this.props.getCart(this.props.auth.accessToken);

        // }
        if (prevProps.auth.accessToken !== this.props.auth.accessToken) {
            // this.props.getCart(this.props.auth.accessToken);
            if (Object.keys(this.props.branchOptions.data).length > 0) {
                    const branchSelected = this.props.branchOptions.data.cities.find(el => {
                        return parseInt(el.id) === 4;
                    });
                    if(this.props.auth.data.sector){
                        this.props.updateSelectedBranch(
                            branchSelected,
                            this.props.auth.data.sector,
                            true
                        );
                    }
            }

        }


        if (
            prevProps.updateDefaultAddress.data !=
            this.props.updateDefaultAddress.data
        ) {
            if (
                !this.props.updateDefaultAddress.hasError &&
                !this.props.updateDefaultAddress.isLoading
            ) {
                this.props.userDefaultBranch(this.props.updateDefaultAddress.data.zone_id, this.props.updateDefaultAddress.data.cityId)

            }
        }


        // if(this.props.auth.accessToken){
        //     if (Object.keys(this.props.branchOptions.data).length > 0) {
        //         if (
        //             prevProps.branchOptions.data !==
        //             this.props.branchOptions.data
        //         ){
        //             const branchSelected = this.props.branchOptions.data.cities.find(el => {
        //                 // return parseInt(el.id) === parseInt(location['cityId']);
        //                 return parseInt(el.id) === 4;
        //             });
        //             if(this.props.auth.data.sector){
        //                 this.props.updateSelectedBranch(
        //                     branchSelected,
        //                     this.props.auth.data.sector,
        //                     true
        //                 );
        //             }
        //         }
        //     }
        // }
    }

    render() {
        return (
            <BrowserRouter>
                <Suspense fallback={<div className="loader"></div>}>
                    <Switch>
                        <PublicRoute path="/" exact component={Welcome} />
                        <PublicRoute
                            path="/aboutus"
                            exact
                            component={Aboutus}
                        />
                        <PublicRoute
                            path="/contactus"
                            exact
                            component={Contactus}
                        />
                        <PublicRoute path="/cart" exact component={Cart} />
                        <PublicRoute
                            path="/shop/:category"
                            exact
                            component={Shop}
                        />
                        <Route path="/app/login" component={AppLogin} />
                        {/* <PublicRoute
                            path="/fresh-fruits"
                            exact
                            component={Shop}
                            category="Fresh Fruits"
                        />
                        <PublicRoute
                            path="/fresh-vegetables"
                            exact
                            category="Fresh Vegetables"
                            component={Shop}
                        />

                        <PublicRoute
                            path="/farm-to-home-special"
                            exact
                            category="Farm To Home Special"
                            component={Shop}
                        /> */}
                        {/* <PublicRoute
                            path="/fresh-vegetables/:productName/:productId"
                            category="fresh-vegetables"
                            component={Product}
                        />
                        <PublicRoute
                            path="/fresh-fruits/:productName/:productId"
                            category="fresh-fruits"
                            component={Product}
                        />
                        <PublicRoute
                            path="/farm-to-home-special/:productName/:productId"
                            category="farm-to-home-special"
                            component={Product}
                        /> */}

                        <PublicRoute
                            path="/:productCategory/:productName/:productId"
                            component={Product}
                        />
                        <PublicRoute path="/search" component={Search} />
                        <PublicRoute
                            path="/privacyPolicy"
                            component={PrivacyPolicy}
                        />
                        <PublicRoute
                            path="/account-deletion-policy"
                            component={AccountDeletionPolicy}
                        />
                        <PublicRoute path="/FAQs" component={Faqs} />
                        <PublicRoute path="/faqs" component={Faqs} />
                        <PublicRoute
                            path="/delivery-coverage"
                            exact
                            component={DeliveryCoverage}
                        />
                        <GuestRoute path="/signin" exact component={Signin} />
                        <GuestRoute exact path="/signup" component={Signup} />
                        <ForgetPassRoute
                            exact
                            path="/forget-password"
                            component={ForgetPassword}
                        />
                        <GuestRoute
                            exact
                            path="/checkout"
                            component={GuestCheckout}
                        />
                        <ForgetPassRoute
                            exact
                            path="/forget-password/verify"
                            component={PasswordPincode}
                        />
                        <ForgetPassRoute
                            exact
                            path="/forget-password/change-password"
                            component={ChangePassword}
                        />

                        <SocialRoute
                            exact
                            path="/social/profile"
                            component={SocialProfile}
                        />
                        <AuthRoute
                            exact
                            path="/user/profile"
                            component={UserProfile}
                        />
                        <AuthRoute
                            exact
                            path="/user/change-password"
                            component={UserChangePassword}
                        />
                        <AuthRoute
                            exact
                            path="/user/orders"
                            component={OrderHistory}
                        />
                        <AuthRoute
                            exact
                            path="/orders/:orderId"
                            component={OrderDetail}
                        />
                        <AuthRoute path="/user/checkout" component={Checkout} />
                        {/* <AuthRoute
                            path="/user/reviews"
                            component={UserReviews}
                        /> */}
                        <PublicRoute component={Default} />
                    </Switch>
                </Suspense>
                <a
                    href="https://wa.me/+923015551155"
                    className="whatsapp_float"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {/* <i className="fa fa-whatsapp whatsapp-icon-bootom"></i> */}
                    <svg class="whatsapp-icon" viewBox="0 0 512 512">
                        <path d="M0,512l35.31-128C12.359,344.276,0,300.138,0,254.234C0,114.759,114.759,0,255.117,0  S512,114.759,512,254.234S395.476,512,255.117,512c-44.138,0-86.51-14.124-124.469-35.31L0,512z"></path>
                        <path d="M137.71,430.786l7.945,4.414c32.662,20.303,70.621,32.662,110.345,32.662  c115.641,0,211.862-96.221,211.862-213.628S371.641,44.138,255.117,44.138S44.138,137.71,44.138,254.234  c0,40.607,11.476,80.331,32.662,113.876l5.297,7.945l-20.303,74.152L137.71,430.786z"></path>
                        <path d="M187.145,135.945l-16.772-0.883c-5.297,0-10.593,1.766-14.124,5.297  c-7.945,7.062-21.186,20.303-24.717,37.959c-6.179,26.483,3.531,58.262,26.483,90.041s67.09,82.979,144.772,105.048  c24.717,7.062,44.138,2.648,60.028-7.062c12.359-7.945,20.303-20.303,22.952-33.545l2.648-12.359  c0.883-3.531-0.883-7.945-4.414-9.71l-55.614-25.6c-3.531-1.766-7.945-0.883-10.593,2.648l-22.069,28.248  c-1.766,1.766-4.414,2.648-7.062,1.766c-15.007-5.297-65.324-26.483-92.69-79.448c-0.883-2.648-0.883-5.297,0.883-7.062  l21.186-23.834c1.766-2.648,2.648-6.179,1.766-8.828l-25.6-57.379C193.324,138.593,190.676,135.945,187.145,135.945"></path>
                    </svg>
                </a>
            </BrowserRouter>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth.user,
    branchSelected: state.branch.branchSelected,
    userCart: state.cart.userCart,
    branchOptions: state.branch.branchOptions,
    updateDefaultAddress: state.userAddress.updateDefault,

});

export default connect(mapStateToProps, {
    getUserInfo,
    getBranchOptions,
    getCategoryProducts,
    getCart,
    addToCart,
    clearCart,
    updateSelectedBranch,
    userDefaultBranch
})(App);
