import {
    GET_PRODUCT,
    GET_PRODUCTS,
    GET_PRODUCTS_NAMES,
    GET_CATEGORY_PRODUCTS,
    GET_FEATURED_PRODUCTS,
    SUBMIT_PRODUCT_REVIEW,
    GET_SEARCH_PRODUCTS,
    GET_CATEGORY
} from "../actions/types";

const initialState = {
    categoryProducts: {
        isLoading: true,
        hasError: false,
        message: "",
        data: {}
    },
    featuredProducts: {
        isLoading: true,
        hasError: false,
        message: "",
        data: {}
    },
    searchProducts: {
        isLoading: true,
        hasError: false,
        message: "",
        data: {}
    },
    products: {
        isLoading: true,
        hasError: false,
        message: "",
        data: {}
    },
    product: {
        isLoading: true,
        hasError: false,
        message: "",
        isEmpty: false,
        data: {}
    },
    productsNames: {
        isLoading: true,
        hasError: false,
        message: "",
        data: {}
    },
    submitReview: {
        isLoading: true,
        hasError: false,
        message: "",
        data: {}
    },
    category: {
        isLoading: true,
        hasError: false,
        message: "",
        data: {}
    }
};

export default function(state = initialState, action) {
    switch (action.type) {
        case GET_CATEGORY_PRODUCTS:
            return {
                ...state,
                categoryProducts: action.payload
            };
        case GET_FEATURED_PRODUCTS:
            return {
                ...state,
                featuredProducts: action.payload
            };
        case GET_SEARCH_PRODUCTS:
            return {
                ...state,
                searchProducts: action.payload
            };
        case GET_PRODUCTS:
            return {
                ...state,
                products: action.payload
            };
        case GET_PRODUCT:
            return {
                ...state,
                product: action.payload
            };
        case GET_PRODUCTS_NAMES:
            return {
                ...state,
                productsNames: action.payload
            };
        case SUBMIT_PRODUCT_REVIEW:
            return {
                ...state,
                submitReview: action.payload
            };
        case GET_CATEGORY:
            return {
                ...state,
                category: action.payload
            };
        default:
            return state;
    }
}
