import {
    GET_BRANCH_OPTIONS,
    GET_BRANCH,
    BRANCH_MODAL,
    SELECTED_BRANCH,
    GET_BRANCH_FAQS,
    GET_USER_CART,
    USER_BRANCH
} from "./types";
import * as Sentry from "@sentry/react";

export const getBranchOptions = () => {
    return async (dispatch, getState) => {
        dispatch({
            type: GET_BRANCH_OPTIONS,
            payload: {
                isLoading: true,
                hasError: false,
                data: {},
                message: ""
            }
        });
        const branch = getState().branch.branchSelected;
        await axios
            .get(`/api/web/options?city_id=${branch.branchId}`)
            .then(res => {
                // const branch = getState().branch.branchSelected;
                if (branch.isBranchSelected) {
                    // const branchSelected = res.data.data.cities.find(
                    //     x => x.id === branch.branchId
                    // );

                    const branchSelected = branch.isBranchSelected
                        ? branch.branch.deliveryLocations.filter(
                            obj => obj.area === branch.branchArea
                        )
                    : 1;

                    const banners = res.data.data.banners;
                    const isLoggedIn = getState().auth.user.isLoggedIn;
                    if (branch.branchId) {

                        if(isLoggedIn)
                        {
                            dispatch({
                                type: SELECTED_BRANCH,
                                payload: {
                                    isLoading: false,
                                    hasError: false,
                                    isBranchSelected: true,
                                    branch: branch.branch,
                                    branchId:getState().auth.user.data.defaultCityId,
                                    branchArea: branch.branchArea,
                                    cityId: branch.branch.id,
                                    banner: banners,
                                    defaultCityId:getState().auth.user.data.defaultCityId,
                                    defaultZoneId:getState().auth.user.data.defaultZoneId,
                                    message: "",
                                    zoneId: branchSelected[0].zoneId,
                                    test: "Banner"
                                }
                            });
                            dispatch({
                                type: USER_BRANCH,
                                payload: {
                                    defaultCityId:getState().auth.user.data.defaultCityId,
                                    defaultZoneId:getState().auth.user.data.defaultZoneId,
                                }
                            });
                        }  else{
                            dispatch({
                                type: SELECTED_BRANCH,
                                payload: {
                                    isLoading: false,
                                    hasError: false,
                                    isBranchSelected: true,
                                    branch: branch.branch,
                                    branchId: branch.branchId,
                                    branchArea: branch.branchArea,
                                    cityId: branch.branch.id,
                                    banner: banners,
                                    message: "",
                                    zoneId: branchSelected[0].zoneId,
                                    defaultCityId:getState().auth.user.data.defaultCityId,
                                    defaultZoneId:getState().auth.user.data.defaultZoneId,
                                    test: "Banner"
                                }
                            });
                        }

                    } else {
                        dispatch({
                            type: SELECTED_BRANCH,
                            payload: {
                                isLoading: false,
                                hasError: false,
                                isBranchSelected: false,
                                branch: "",
                                branchId: "",
                                branchArea: "",
                                message: "",
                                zoneId: branchSelected[0].zoneId,
                                test: "Banner 1"
                            }
                        });
                    }
                }
                dispatch({
                    type: GET_BRANCH_OPTIONS,
                    payload: {
                        isLoading: false,
                        hasError: false,
                        data: res.data.data,
                        message: res.data.message,
                        banners: res.data.data.banners
                    }
                });
            })
            .catch(err => {
                if (err.response.data.statusCode == 400) {
                    dispatch({
                        type: GET_BRANCH_OPTIONS,
                        payload: {
                            isLoading: false,
                            hasError: true,
                            message: err.response.data.message
                        }
                    });
                } else {
                    Sentry.withScope(scope => {
                        scope.setExtra("title", "authAction@getBranchOptions");
                        scope.setExtra("response", err.response);
                        Sentry.captureException(err);
                    });
                    dispatch({
                        type: GET_BRANCH_OPTIONS,
                        payload: {
                            isLoading: false,
                            hasError: true,
                            data: {},
                            message: "Something went wrong, try again later"
                        }
                    });
                }
            });
    };
};

export const getCart = token => {
    return (dispatch, getState) => {
        dispatch({
            type: GET_USER_CART,
            payload: {
                isLoading: true,
                hasError: false,
                data: {},
                message: ""
            }
        });

        const branch = getState().branch.branchSelected;
        const branchId = branch.isBranchSelected ? branch.branchId : 1;
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        const selectedBranch = branch.isBranchSelected
        ? branch.branch.deliveryLocations.filter(
              obj => obj.area === branch.branchArea
          )
        : 1;
        let sectorId = 0;
        if(selectedBranch != 1)
            sectorId = selectedBranch.map(obj => obj.id);

        if (token && sectorId != 0) {
            axios
                .get(`/api/user/v2/cart?city_id=${branchId}&sector_id=${sectorId}`)
                .then(res => {
                    dispatch({
                        type: GET_USER_CART,
                        payload: {
                            isLoading: false,
                            hasError: false,
                            data: res.data.data,
                            message: ""
                        }
                    });
                })
                .catch(err => {
                    dispatch({
                        type: GET_USER_CART,
                        payload: {
                            isLoading: false,
                            hasError: true,
                            data: {},
                            message: ""
                        }
                    });
                });
        }
    };
};

export const getBranch = branch => dispatch => {
    return dispatch({
        type: GET_BRANCH,
        payload: {
            data: branch
        }
    });
};

export const updateSelectedBranch = (branch, area, status) => (
    dispatch,
    getState
) => {
    const state = getState().branch.branchSelected;
    const branchId = branch.deliveryLocations.filter(obj => obj.area === area);
    const cityId = branchId.map(obj => obj.cityId);
    const zoneId = branchId.map(obj => obj.zoneId);

    const isLoggedIn = getState().auth.user.isLoggedIn;
    console.log('getState()',getState().auth.user.data.defaultCityId);
    try {

        if(isLoggedIn)
        {
            dispatch({
                type: SELECTED_BRANCH,
                payload: {
                    isLoading: false,
                    hasError: false,
                    isBranchSelected: status,
                    branch: branch,
                    branchId:getState().auth.user.data.defaultCityId,
                    // branchId: branch.id,  original city id
                    // branchId: cityId[0],
                    // branchId: branchId[0].zoneId,
                    cityId: branch.id,
                    newBranchId: cityId[0],
                    zoneId: zoneId[0],
                    branchArea: area,
                    zoneId:branchId[0].zoneId,
                    defaultCityId:getState().auth.user.data.defaultCityId,
                    defaultZoneId:getState().auth.user.data.defaultZoneId,
                    message: ""
                }
            });

            dispatch({
                type: USER_BRANCH,
                payload: {
                    defaultCityId:getState().auth.user.data.defaultCityId,
                    defaultZoneId:getState().auth.user.data.defaultZoneId,
                }
            });

        }else{
            dispatch({
                type: SELECTED_BRANCH,
                payload: {
                    isLoading: false,
                    hasError: false,
                    isBranchSelected: status,
                    branch: branch,
                    branchId: cityId[0],
                    // branchId: branchId[0].zoneId,
                    cityId: branch.id,
                    zoneId: zoneId[0],
                    branchArea: area,
                    zoneId:branchId[0].zoneId,
                    message: ""
                }
            });
        }

    } catch (err) {
        Sentry.withScope(scope => {
            scope.setExtra("title", "branchActions@updateSelectedBranch");
            scope.setExtra("response", err);
            Sentry.captureException(err);
        });

        dispatch({
            type: SELECTED_BRANCH,
            payload: {
                isLoading: false,
                hasError: true,
                isBranchSelected: state.isBranchSelected,
                branch: state.branch,
                branchId: state.branchId,
                branchArea: state.branchArea,
                // zoneId:branchId[0].zoneId,
                message: "Something went wrong, please try again."
            }
        });
    }
};


export const userDefaultBranch = (zoneId, cityId) => (
    dispatch,
    getState
) => {
    // console.log('zoneId',zoneId);
    // console.log('cityId',cityId);
    dispatch({
        type: USER_BRANCH,
        payload: {
            defaultCityId:cityId,
            defaultZoneId:zoneId,
        }
    });
}


export const showBranchModal = status => dispatch => {
    return dispatch({
        type: BRANCH_MODAL,
        payload: {
            isLoading: false,
            hasError: false,
            isActive: status,
            message: ""
        }
    });
};

export const getFaqs = () => {
    return async (dispatch, getState) => {
        dispatch({
            type: GET_BRANCH_FAQS,
            payload: {
                isLoading: true,
                hasError: false,
                data: {},
                message: ""
            }
        });
        const branch = getState().branch.branchSelected;
        const branchId = branch.isBranchSelected ? branch.branchId : 1;
        await axios
            .get(`/api/faqs?city_id=${branchId}`)
            .then(res => {
                dispatch({
                    type: GET_BRANCH_FAQS,
                    payload: {
                        isLoading: false,
                        hasError: false,
                        data: res.data.data,
                        message: res.data.message
                    }
                });
            })
            .catch(err => {
                if (err.response.data.statusCode == 400) {
                    dispatch({
                        type: GET_BRANCH_FAQS,
                        payload: {
                            isLoading: false,
                            hasError: true,
                            message: err.response.data.message
                        }
                    });
                } else {
                    Sentry.withScope(scope => {
                        scope.setExtra("title", "branchActions@getBranchFaqs");
                        scope.setExtra("response", err.response);
                        Sentry.captureException(err);
                    });
                    dispatch({
                        type: GET_BRANCH_FAQS,
                        payload: {
                            isLoading: false,
                            hasError: true,
                            data: {},
                            message: "Something went wrong, try again later"
                        }
                    });
                }
            });
    };
};
